import * as React from "react";

import Layout from "../components/layout";
import Hero from "../components/hero";
import HeroContentHeading from "../components/hero-content-heading";
import TemplateHead from "../components/template-head";

export default function ThankYou() {
  return (
    <Layout>
      <Hero
        altImages="Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        sectionClassName="hero-section hero-section--about pl-4 pr-4"
        bgImageClassName="hero-image hero-image--about"
        contentClassName="hero-content hero-content--about"
      >
        <HeroContentHeading
          headingTitle="Thanks for your order"
          headingContent="We will send an order confirmation to your email."
        />
      </Hero>
    </Layout>
  );
};

export function Head() {
  return (
    <TemplateHead
      title="Thanks for your order - Fenix Funeral Directors"
    />
  );
}